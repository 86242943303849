import React, { useContext, useEffect, useState } from "react";
import NeonLineSideText from "../NeonLineSideText";
import WishlistItem from "../WishlistItem";
import Loading from "../Loading";
import {
  WishlistContext,
  IWishlistContextProps,
} from "../../context/WishlistContext";
import "../../assets/css/wishlist.css";
import "../../assets/css/utils.css";

interface IWishlistProps {}

const Wishlist: React.FC<IWishlistProps> = () => {
  const {
    wishlistProducts,
    removeFromWishlist,
    getWishlistProducts,
  } = useContext(WishlistContext) as IWishlistContextProps;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getWishlistProducts();
    setLoading(false);
  }, [getWishlistProducts]);

  const handleRemoveItem = (id: number) => {
    removeFromWishlist(id);
  };

  return (
    <div className="wishlist-container">
      <div className="wishlist-content-box">
        <div className="wishlist-title">
          <h1>WISHLIST</h1>
        </div>
        <div className="wishlist-items-container">
          <div className="w-item-box">
            <div>
              <NeonLineSideText
                color="violet"
                text="PHYSICAL"
                description=""
                direction="left"
              />
            </div>
            {loading ? (
              <Loading />
            ) : wishlistProducts.length > 0 ? (
              <div className="w-item-list mt-3">
                {wishlistProducts.map(
                  ({ productId, imageUrl, title, createdAt }) => (
                    <WishlistItem
                      key={productId}
                      id={productId}
                      image={imageUrl}
                      title={title}
                      createdAt={createdAt}
                      handleRemove={handleRemoveItem}
                    />
                  )
                )}
              </div>
            ) : (
              <div className="no-items">
                <h2>There are no items</h2>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <NeonLineSideText
          color="blue"
          text=""
          description=""
          direction="left"
        />
      </div>
      <div className="love-it-container">
        <h2 className="mb-5">Love It? Add To My Wishlist</h2>
        <p className="mb-5">
          My Wishlist allows you to keep track of all of your favorites and
          shopping activity whether
          <br /> you're on your computer, phone, or tablet. You won't have to
          waste time searching all over
          <br /> again for that item you loved on your phone the other day -
          it's all here in one place!
        </p>
        <div />
      </div>
    </div>
  );
};

export default Wishlist;
