import React from "react";
import blueLeft from "../../assets/images/utils/blue_neon_line_left.svg";
import blueRight from "../../assets/images/utils/blue_neon_line_right.svg";
import violet from "../../assets/images/utils/violet_neon_line.svg";
import "../../assets/css/neonlinesidetext.css";

interface INeonLineSideTextProps {
  text: string;
  description: string;
  direction?: string;
  color: string;
}

const NeonLineSideText: React.FC<INeonLineSideTextProps> = ({
  color,
  text,
  description,
  direction = "left",
}) => {
  let image;
  if (color === "blue") {
    if (direction === "left") {
      image = blueLeft;
    } else {
      image = blueRight;
    }
  } else {
    image = violet;
  }

  return (
    <div
      className="line-side-container"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      {direction === "left" ? (
        <div className="text-description-container">
          <div className="text-left" style={{ paddingLeft: "20px" }}>
            <span>{text}</span>
          </div>
          <div className="description-left" style={{ paddingLeft: "20px" }}>
            <span>{description}</span>
          </div>
        </div>
      ) : (
        <div className="text-description-container">
          <div className="text-right">
            <span className="right-20">{text}</span>
          </div>
          <div className="description-right">
            <span className="right-20">{description}</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default NeonLineSideText;
