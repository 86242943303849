import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import profile from "../../assets/images/options/profile.svg";
import add from "../../assets/images/utils/add.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faWallet } from "@fortawesome/free-solid-svg-icons";
import { PURCHASES } from "../../routes/routes";
import "../../assets/css/connect.css";
import { Web3Context } from "../../context/Web3Context";

const Connect: React.FC = () => {
  const { walletAddress, initializeWeb3 } = useContext(Web3Context);
  const [isauth, setIsAuth] = useState<boolean>(false);
  const history = useHistory();

  const toHistory = () => {
    history.push(PURCHASES);
  };

  useEffect(() => {
    !walletAddress ? setIsAuth(false) : setIsAuth(true);
  }, [walletAddress]);

  return (
    <div className="connect-container">
      {isauth ? (
        <>
          <div className="title">
            <div>
              <img src={profile} alt="profile" />
              <h2>My Account</h2>
            </div>

            {walletAddress !== "" && (
              <div
                className="go-history cursor-pointer"
                onClick={() => {
                  toHistory();
                }}
              >
                <span>PURCHASES HISTORY</span>
                <FontAwesomeIcon icon={faChevronRight} size="lg" />
              </div>
            )}
          </div>
          <h2>Wallet</h2>
          <div className="connected">
            <div className="wallet-info">
              <div
                className="user-info"
                onClick={walletAddress === "" ? initializeWeb3 : undefined}
              >
                <div>
                  <FontAwesomeIcon icon={faWallet} size="lg" />
                </div>
                <h2>Wallet:</h2>
                <span className="address-mobile">{`${walletAddress.substr(
                  0,
                  6
                )}...${walletAddress.substr(
                  walletAddress.length - 4,
                  walletAddress.length
                )}`}</span>
                <span className="address-desktop">{walletAddress}</span>
              </div>
            </div>

            <div className="disconnect-button cursor-pointer" onClick={()=> {localStorage.removeItem('walletAddress'); localStorage.removeItem('signature'); window.location.reload();}}>
              <div>Disconnect</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <span>Wallet</span>
          <div className="my-account">
            <div>
              <img src={profile} alt="profile icon" />
              <div>My Account</div>
            </div>
          </div>
          <div className="not-connected" onClick={initializeWeb3}>
            <img src={add} alt="add icon" />
            <div>Connect Wallet</div>
          </div>
        </>
      )}
    </div>
  );
};

export default Connect;
