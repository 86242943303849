import Layout from "./components/Layout";
import CartProvider from "./context/CartContext";
import Web3ContextProvider from "./context/Web3Context";
import WishlistProvider from "./context/WishlistContext";
import useAffiliateCode from "./hooks/useAffiliateCode";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-NHLFKVD",
};

TagManager.initialize(tagManagerArgs);

const App = (): JSX.Element => {
  useAffiliateCode();

  return (
    <Web3ContextProvider>
      <WishlistProvider>
        <CartProvider>
          <Layout />
        </CartProvider>
      </WishlistProvider>
    </Web3ContextProvider>
  );
};

export default App;
