import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HOME, S_1337 } from "../../routes/routes";
import FRACTION_DIGITS from "../../constants/fractionDigits";
import "../../assets/css/exchange.css";
import axios from "axios";
import {
  COIN_GECKO_ERC20_URL,
  COIN_GECKO_URL,
  ETHEREUM_ID,
  LITE_ADDRESS,
  WHALE_ADDRESS,
} from "../../constants/coingecko";

const Exchange: React.FC = () => {
  const repeat: number = 10;
  const { pathname } = useLocation();
  const [showexchange, setShowExchange] = useState(true);
  const [ethPrice, setEthPrice] = useState(0);
  const [litePrice, setLitePrice] = useState(0);
  const [whalePrice, setWhalePrice] = useState(0);

  useEffect(() => {
    switch (pathname) {
      case HOME:
        setShowExchange(true);
        break;
      case S_1337:
        setShowExchange(true);
        break;
      default:
        setShowExchange(false);
        break;
    }
    getEthereumPrice();
    getLitePrice();
    getWhalePrice();
  }, [pathname]);

  const getEthereumPrice = () => {
    axios
      .get(`${COIN_GECKO_URL}${ETHEREUM_ID}`)
      .then((response) => {
        setEthPrice(response.data.market_data.current_price.usd);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const getLitePrice = () => {
    axios
      .get(`${COIN_GECKO_ERC20_URL}${LITE_ADDRESS}`)
      .then((response) => {
        setLitePrice(response.data.market_data.current_price.usd);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const getWhalePrice = () => {
    axios
      .get(`${COIN_GECKO_ERC20_URL}${WHALE_ADDRESS}`)
      .then((response) => {
        setWhalePrice(response.data.market_data.current_price.usd);
      })
      .catch(() => {})
      .finally(() => {});
  };
  return showexchange ? (
    <div className="exchange-container">
      <div className="marquee">
        <div>
          {[...Array(repeat)].map((e, i) => {
            return (
              <Fragment key={i}>
                {ethPrice > 0 && (
                  <span>
                    <span> 1 ETH = </span>{" "}
                    {ethPrice.toLocaleString("en-US", FRACTION_DIGITS)}{" "}
                    United States Dollar
                  </span>
                )}
                {whalePrice > 0 && (
                  <span>
                    <span> 1 WHALE = </span>{" "}
                    {whalePrice.toLocaleString("en-US", FRACTION_DIGITS)}{" "}
                    United States Dollar
                  </span>
                )}
                {litePrice > 0 && (
                  <span>
                    <span> 1 $1337 = </span>{" "}
                    {litePrice.toLocaleString("en-US", FRACTION_DIGITS)}{" "}
                    United States Dollar
                  </span>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default Exchange;
