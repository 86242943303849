import React, { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IScrollToTopProps {
  children: ReactElement<any, any> | null;
}

const ScrollToTop: React.FC<IScrollToTopProps> = ({ children }) => {
  let { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};
export default ScrollToTop;
