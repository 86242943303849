import { useState, useEffect } from "react";

export default function useAffiliateCode() {
  const params = new URLSearchParams(window.location.search);
  const ref = params.get("ref");

  const [affiliateCode, setAffiliateCode] = useState("");

  useEffect(() => {
    if (ref) {
      localStorage.setItem("affiliateCode", ref);
      setAffiliateCode(localStorage.getItem("ref") ?? "");
    }

    setAffiliateCode(localStorage.getItem("affiliateCode") ?? "");
  }, [ref]);

  return affiliateCode;
}
