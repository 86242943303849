import { createContext, Component } from "react";
import Web3 from "web3";
import WalletConnect from "walletconnect";
import axios from "axios";
import { API_BASE_URL } from "../constants/env";

export interface IWeb3Context {
  isLoading: boolean;
  walletAddress: string;
  signature: string;
  initializeWeb3: () => void;
}

declare global {
  interface Window {
    ethereum: any;
  }
}

export const Web3Context = createContext<IWeb3Context>({
  isLoading: false,
  walletAddress: "",
  signature: "",
  initializeWeb3: () => {},
});

interface IProps {}

interface IState {
  isLoading: boolean;
  messageToSign: string;
  walletAddress: string;
  signature: string;
  web3?: Web3;
}

class Web3ContextProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: true,
      messageToSign: "",
      walletAddress: "",
      signature: "",
      web3: undefined,
    };
  }

  componentDidMount = async () => {
    const walletAddress = localStorage.getItem("walletAddress") ?? "";
    this.setState({ walletAddress });
    this.getMessageToSign();
    if (walletAddress !== "") {
      await this.initializeWeb3();
    }

    this.setState({ isLoading: false });
  };

  getMessageToSign = async (): Promise<string> => {
    if (this.state.messageToSign !== "") {
      return this.state.messageToSign;
    }
    const response = await axios.get(`${API_BASE_URL}/message-to-sign`);

    this.setState({ messageToSign: response.data.messageToSign });

    return response.data.messageToSign;
  };

  initializeWeb3 = async () => {
    const rawMessage = await this.getMessageToSign();
    let signature = localStorage.getItem("signature") as string;
    let walletAddress;
    let web3;
    let provider;

    if (window.ethereum) {
      // Metamask
      provider = window.ethereum;
      web3 = new Web3(provider);
      await provider.send("eth_requestAccounts"); // request the user to connect a wallet
      const accounts = await web3.eth.getAccounts(); // gets all the connected accounts
      walletAddress = accounts[0];
      if (!signature) {
        signature = await web3.eth.personal.sign(rawMessage, walletAddress, "");
      }
    } else {
      // wallet connect
      var rawMessageLength = new Blob([rawMessage]).size;
      var message = Web3.utils.fromUtf8(
        "\x19Ethereum Signed Message:\n" + rawMessageLength + rawMessage
      );
      message = Web3.utils.keccak256(message);

      const wc = new WalletConnect();

      const connector = await wc.connect();

      provider = await wc.getWeb3Provider({
        infuraId: "46ad5f56d17e43809fb68788882fda58",
      });
      provider.enable();

      web3 = new Web3(provider as any);
      const accounts = await web3.eth.getAccounts();
      walletAddress = accounts[0];
      const params: any[] = [walletAddress, message];
      if (!signature) {
        signature = await connector.signMessage(params);
      }
    }

    this.setState({ web3, walletAddress, signature }, () => {
      this.setUpProvider(provider);
      localStorage.setItem("walletAddress", walletAddress);
      localStorage.setItem("signature", signature);
    });
  };

  setUpProvider = (provider: any) => {
    provider.on("disconnect", () => {
      this.setState({ walletAddress: "" });
      localStorage.removeItem("walletAddress");
      localStorage.removeItem("signature");
    });

    provider.on("accountsChanged", (accounts: string[]) => {
      this.setState({ walletAddress: "" });
      localStorage.removeItem("walletAddress");
      localStorage.removeItem("signature");
    });
  };

  render() {
    return (
      <Web3Context.Provider
        value={{
          isLoading: this.state.isLoading,
          walletAddress: this.state.walletAddress,
          initializeWeb3: this.initializeWeb3,
          signature: this.state.signature,
        }}
      >
        {this.props.children}
      </Web3Context.Provider>
    );
  }
}

export default Web3ContextProvider;
