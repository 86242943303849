import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faHeart } from "@fortawesome/free-solid-svg-icons";
import { CartContext, ICartContextProps } from "../../context/CartContext";
import isologo from "../../assets/images/menu/isologo.png";
import whiteCart from "../../assets/images/utils/white_cart.svg";
import "../../assets/css/menu.css";
import "../../assets/css/utils.css";
import {
  HOME,
  ABOUT,
  // PHYSICAL,
  DIGITAL,
  LOGIN,
  WISHLIST,
  S_1337,
  PROFILE,
  BLOG,
  ARTGALLERY,
} from "../../routes/routes";
import { Web3Context } from "../../context/Web3Context";

const Menu: React.FC = () => {
  let { pathname } = useLocation();
  const { showCart } = useContext(CartContext) as ICartContextProps;
  const { walletAddress, signature, initializeWeb3 } = useContext(Web3Context);

  return (
    <div className="menu-container">
      <ul className="menu-list">
        <li className="menu-button isologo pl-3">
          <Link to={HOME} aria-label="home">
            <img src={isologo} alt="e1337 isologo" />
          </Link>
        </li>
        <li className={`${pathname === HOME ? "active" : ""} menu-button`}>
          <Link to={HOME} aria-label="home">
            Home
          </Link>
        </li>
        <li className={`${pathname === ABOUT ? "active" : ""} menu-button`}>
          <Link to={ABOUT} aria-label="about">
            About Us
          </Link>
        </li>
        {/* <li
          className={`${
            pathname.includes(PHYSICAL) ? "active" : ""
          } menu-button`}
        >
          <Link to={PHYSICAL} aria-label="shop physical">
            Shop Physical
          </Link>
        </li> */}
        <li
          className={`${
            pathname.includes(DIGITAL) ? "active" : ""
          } menu-button`}
        >
          <Link to={DIGITAL} aria-label="shop digital">
            Shop Digital
          </Link>
        </li>
        <li
          className={`${pathname.includes(S_1337) ? "active" : ""} menu-button`}
        >
          <Link to={S_1337} aria-label="$1337">
            $1337
          </Link>
        </li>
        <li
          className={`${
            pathname.includes(ARTGALLERY) ? "active" : ""
          } menu-button`}
        >
          <Link to={ARTGALLERY} aria-label="art gallery">
            Art Gallery
          </Link>
        </li>
        <li
          className={`${pathname.includes(BLOG) ? "active" : ""} menu-button`}
        >
          <Link to={BLOG} aria-label="blog">
            Blog
          </Link>
        </li>
        <li className="menu-button d-inline-block m-left-auto">
          <div className="input-group">
            {/* <div className="input-group-prepend">
              <span className="input-group-text bc-dark-grey" id="basic-addon1">
                <img src={magnifier_svg} alt="Magnifier icon" />
              </span>
            </div>
            <input
              type="text"
              className="form-control bc-dark-grey"
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon1"
            /> */}
          </div>
        </li>

        <li
          className={`${
            pathname.includes(LOGIN) ? "active" : ""
          } menu-button menu-icon`}
        >
          <Link to={PROFILE} aria-label="profile">
            <FontAwesomeIcon icon={faUser} />
          </Link>
        </li>
        {walletAddress !== "" && signature !== "" && (
          <li
            className={`${
              pathname.includes(WISHLIST) ? "active" : ""
            } menu-button menu-icon`}
          >
            <Link to={WISHLIST} aria-label="wishlist">
              <FontAwesomeIcon icon={faHeart} />
            </Link>
          </li>
        )}
        {/* <li className="menu-button menu-icon">
          <button
            className="cursor-pointer"
            onClick={showCart}
            aria-label="cart"
          >
            <img src={whiteCart} alt="" />
          </button>
        </li> */}
        <li
          className={`${
            pathname.includes(PROFILE) ? "active-violet" : ""
          } menu-button-violet`}
        >
          {walletAddress === "" || signature === "" ? (
            <button
              aria-label="connect wallet"
              className="connect-button cursor-pointer"
              onClick={initializeWeb3}
            >
              Connect
            </button>
          ) : (
            <button
              aria-label="disconnect wallet"
              className="connect-button cursor-pointer"
              onClick={() => {
                localStorage.removeItem("walletAddress");
                localStorage.removeItem("signature");
                window.location.reload();
              }}
            >
              Disconnect
            </button>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Menu;
