export const T_PHYSICAL = "Shop Physical";
export const T_DIGITAL = "Shop Digital";
export const T_ART_GALLERY = "Art Gallery";
export const T_HOME = "Home";
export const T_SHOP = "Shop";
export const T_MY_ACCOUNT = "My Account";
export const T_BULK_WHOLE = "Bulk Orders & Wholesale";
export const T_BLOG = "Blog";
export const T_SEARCH = "Search";
export const T_S1337 = "$1337";
export const T_LOOKBOOK = "Lookbook";
export const T_BLOCKCHAIN = "Blockchain";
export const T_SUBSCRIBE = "Subscribe";
export const T_MEDIA = "Media";
export const T_CART = "Shopping Cart";
export const T_PRIVACY = "Privacy Policy";
export const T_TERMS = "Terms of Use";
export const T_PURCHASES = "Purchases History";
export const T_WISHLIST = "Wishlist";
export const T_ABOUT = "About us";
export const T_ORDER = "Order Details";
