import React, { Fragment, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import {
  HOME,
  ARTGALLERY,
  PROFILE,
  ABOUT,
  // PHYSICAL,
  DIGITAL,
  S_1337,
  WISHLIST,
  MEDIA,
  BLOG,
  POST,
  SHOP,
  MENU,
  // CART,
  SHIPPING,
  PRIVACY,
  TERMS,
  PURCHASES,
  SUCCESS,
  PRODUCT_PHYSICAL_DETAIL,
  PRODUCT_DIGITAL_DETAIL,
  FAQ,
  ORDERDETAILS,
  SUBSCRIBE,
} from "../../routes/routes";

import MobileHeader from "../MobileHeader";
import MobileMenu from "../MobileMenu";
import Exchange from "../Exchange";
import Menu from "../Menu";
import Loading from "../Loading";
import Footer from "../Footer";
import Wishlist from "../Wishlist";
import Connect from "../Connect";

const Privacy = lazy(() => import("../Privacy"));
const Post = lazy(() => import("../BlogPost"));
const About = lazy(() => import("../About"));
const Media = lazy(() => import("../Media"));
const S1337 = lazy(() => import("../S1337"));
const Home = lazy(() => import("../Home"));
const Blog = lazy(() => import("../Blog"));
// const Cart = lazy(() => import("../Cart"));
const Faq = lazy(() => import("../Faq"));
const ShippingData = lazy(() => import("../ShippingData"));
const OrderDetails = lazy(() => import("../OrderDetails"));
const ShopDigital = lazy(() => import("../ShopDigital"));
// const MobileCart = lazy(() => import("../MobileCart"));
const MobileShop = lazy(() => import("../MobileShop"));
const ArtGallery = lazy(() => import("../ArtGallery"));
const TermsOfUse = lazy(() => import("../TermsOfUse"));
const Subscribe = lazy(() => import("../Subscribe"));
const NotFound = lazy(() => import("../NotFound"));
const Success = lazy(() => import("../Success"));
const MobileOptions = lazy(() => import("../MobileOptions"));
// const ShopPhysical = lazy(() => import("../ShopPhysical"));
const ProductPhysicalDetail = lazy(() => import("../ProductPhysicalDetail"));
const ProductDigitalDetail = lazy(() => import("../ProductDigitalDetail"));
const PurchaseHistory = lazy(() => import("../PurchaseHistory"));

const renderLoader = () => <Loading />;

const Layout: React.FC = () => {
  return (
    <Fragment>
      <header>
        {/* <Cart /> */}
        <MobileHeader />
        <Exchange />
        <Menu />
      </header>
      <main>
        <Toaster />
        <Switch>
          <Route exact path={PROFILE} component={Connect} />
          <Route exact path={WISHLIST} component={Wishlist} />
          <Suspense fallback={renderLoader()}>
            <Route exact path={PURCHASES} component={PurchaseHistory} />
            <Route exact path={ORDERDETAILS} component={OrderDetails} />
            <Route exact path={ARTGALLERY} component={ArtGallery} />
            <Route exact path={SHIPPING} component={ShippingData} />
            {/* <Route exact path={PHYSICAL} component={ShopPhysical} /> */}
            <Route exact path={DIGITAL} component={ShopDigital} />
            <Route exact path={MENU} component={MobileOptions} />
            <Route exact path={SUBSCRIBE} component={Subscribe} />
            <Route exact path={TERMS} component={TermsOfUse} />
            {/* <Route exact path={CART} component={MobileCart} /> */}
            <Route exact path={PRIVACY} component={Privacy} />
            <Route exact path={SUCCESS} component={Success} />
            <Route exact path={SHOP} component={MobileShop} />
            <Route exact path={S_1337} component={S1337} />
            <Route exact path={MEDIA} component={Media} />
            <Route exact path={ABOUT} component={About} />
            <Route exact path={BLOG} component={Blog} />
            <Route exact path={BLOG} component={Blog} />
            <Route exact path={POST} component={Post} />
            <Route exact path={HOME} component={Home} />
            <Route exact path={FAQ} component={Faq} />
            <Route
              exact
              path={PRODUCT_PHYSICAL_DETAIL}
              component={ProductPhysicalDetail}
            />
            <Route
              path={PRODUCT_DIGITAL_DETAIL}
              component={ProductDigitalDetail}
              exact
            />
          </Suspense>
          <Route component={NotFound} />
        </Switch>
      </main>
      <footer>
        <Footer />
        <MobileMenu />
      </footer>
    </Fragment>
  );
};

export default Layout;
