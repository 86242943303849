import React, { useEffect, useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import diamond from "../../assets/images/utils/leet_icon.png";
import goBackArrow from "../../assets/images/utils/go_back_arrow.svg";
import profile from "../../assets/images/options/profile.svg";
import reviewChevronRight from "../../assets/images/utils/review_chevron_right.svg";
import {
  HOME,
  MENU,
  SHOP,
  BLOG,
  PHYSICAL,
  DIGITAL,
  BLOCKCHAIN,
  MEDIA,
  S_1337,
  CART,
  PROFILE,
  PRIVACY,
  TERMS,
  PURCHASES,
  WISHLIST,
  ABOUT,
  SUBSCRIBE,
  ARTGALLERY,
} from "../../routes/routes";
import {
  T_BLOG,
  T_DIGITAL,
  T_ART_GALLERY,
  T_HOME,
  T_MY_ACCOUNT,
  T_PHYSICAL,
  T_SHOP,
  T_S1337,
  T_BLOCKCHAIN,
  T_MEDIA,
  T_CART,
  T_PRIVACY,
  T_TERMS,
  T_PURCHASES,
  T_WISHLIST,
  T_ABOUT,
  T_ORDER,
  T_SUBSCRIBE,
} from "../../constants/titles";
import "../../assets/css/mobileheader.css";

const MobileHeader: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [title, setTitle] = useState<string>("");
  const [showdiamond, setShowDiamond] = useState<boolean>(false);
  const [headerHide, setHeaderHide] = useState<boolean>(false);
  const [lastlocation, setLastLocation] = useState<string>("");

  const toConnect = () => {
    history.push(PROFILE);
  };

  useEffect(() => {
    if (pathname.includes(PHYSICAL)) {
      setTitle(T_PHYSICAL);
      setLastLocation(PHYSICAL);
    }

    if (pathname.includes("order-details")) {
      setTitle(T_ORDER);
      setLastLocation(PURCHASES);
    }

    if (pathname.includes(DIGITAL)) {
      setTitle(T_DIGITAL);
      setLastLocation(DIGITAL);
    }

    if (pathname.includes(BLOG)) {
      setTitle(T_BLOG);
      setLastLocation(BLOG);
    }

    switch (pathname) {
      case HOME:
        setTitle(T_HOME);
        setHeaderHide(false);
        setLastLocation("");
        break;
      case ARTGALLERY:
        setTitle(T_ART_GALLERY);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case SUBSCRIBE:
        setTitle(T_SUBSCRIBE);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case SHOP:
        setTitle(T_SHOP);
        setHeaderHide(false);
        setLastLocation("");
        break;
      case ABOUT:
        setTitle(T_ABOUT);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case MENU:
        setTitle(T_MY_ACCOUNT);
        setHeaderHide(false);
        setLastLocation("");
        break;
      case PROFILE:
        setTitle(T_MY_ACCOUNT);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case PHYSICAL:
        setTitle(T_PHYSICAL);
        setHeaderHide(false);
        setLastLocation(SHOP);
        break;
      case DIGITAL:
        setTitle(T_DIGITAL);
        setHeaderHide(false);
        setLastLocation(SHOP);
        break;
      case S_1337:
        setTitle(T_S1337);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case BLOG:
        setTitle(T_BLOG);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case BLOCKCHAIN:
        setTitle(T_BLOCKCHAIN);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case MEDIA:
        setTitle(T_MEDIA);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case CART:
        setTitle(T_CART);
        setHeaderHide(false);
        setLastLocation("");
        break;
      case PRIVACY:
        setTitle(T_PRIVACY);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case TERMS:
        setTitle(T_TERMS);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      case PURCHASES:
        setTitle(T_PURCHASES);
        setHeaderHide(false);
        setLastLocation(PROFILE);
        break;
      case WISHLIST:
        setTitle(T_WISHLIST);
        setHeaderHide(false);
        setLastLocation(MENU);
        break;
      default:
        break;
    }

    setShowDiamond(pathname !== HOME && pathname !== MENU ? true : false);
  }, [pathname]);

  return !headerHide ? (
    <div
      className="header-mobile cursor-pointer"
      onClick={() => {
        pathname === MENU && toConnect();
      }}
    >
      {lastlocation && (
        <Link to={lastlocation}>
          <img src={goBackArrow} alt="Back arrow" />
        </Link>
      )}
      {pathname === MENU && (
        <div className="account-icon">
          <img src={profile} alt="profile pic" />
        </div>
      )}
      <h2>{title}</h2>
      {showdiamond && <img src={diamond} alt="diamond" />}
      {pathname === MENU && (
        <div className="go-account-arrow">
          <img src={reviewChevronRight} alt="" />
        </div>
      )}
    </div>
  ) : null;
};

export default MobileHeader;
