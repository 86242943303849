import React from "react";
import trashBucket from "../../assets/images/wishlist/trash_bucket.svg";
import "../../assets/css/wishlistitem.css";

interface IWishlistItemProps {
  id: number;
  image: string;
  title: string;
  unitPrice?: number;
  createdAt: string;
  handleRemove: (id: number) => void;
}

const WishlistItem: React.FC<IWishlistItemProps> = ({
  id,
  image,
  title,
  unitPrice,
  handleRemove,
}) => {
  return (
    <div className="wishlist-item-box mb-4">
      <div className="item-box-image mr-5">
        <img src={image} alt="Item" />
      </div>
      <div className="children-container">
        <div className="item-attr">
          <span className="mb-2">{title}</span>
        </div>
        <div className="item-box-prices m-left-auto">
          <span>{unitPrice}</span>
          <button className="cursor-pointer" onClick={() => handleRemove(id)}>
            <img src={trashBucket} alt="Dots" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default WishlistItem;
