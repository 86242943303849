export const HOME = "/";
export const PROFILE = "/profile";
export const ABOUT = "/about-us";
export const MEDIA = "/media";
export const BLOCKCHAIN = "/blockchain";
export const PHYSICAL = "/shop-physical";
export const DIGITAL = "/shop-digital";
export const SHOP = "/shop";
export const LOGIN = "/login";
export const BLOG = "/blog";
export const POST = "/blog-post/:id/:handle";
export const WISHLIST = "/wishlist";
export const BULK = "/bulk-orders-&-wholesale";
export const MENU = "/menu";
export const S_1337 = "/s1337";
export const ARTGALLERY = "/art-gallery";
export const PRODUCT_PHYSICAL_DETAIL = "/shop-physical/product/:id/:handle";
export const PRODUCT_DIGITAL_DETAIL = "/shop-digital/product/:id";
export const PRODUCT_ART_DETAIL = "/art-gallery/product/:id";
export const CART = "/cart";
export const SEARCH = "/search";
export const LOOKBOOK = "/lookbook";
export const SUBSCRIBE = "/subscribe";
export const PURCHASES = "/purchases";
export const PRIVACY = "/privacy-policy";
export const TERMS = "/terms-of-use";
export const SHIPPING = "/shipping-data";
export const FAQ = "/faq";
export const SUCCESS = "/success";
export const ORDERDETAILS = "/order-details/:uuid";
