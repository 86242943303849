import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faDiscord,
  faYoutube,
  faLinkedinIn,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import {
  BLOG,
  ABOUT,
  MEDIA,
  PRIVACY,
  TERMS,
  PHYSICAL,
  DIGITAL,
  FAQ,
} from "../../routes/routes";
import xpaylogo from "../../assets/images/utils/white_xpay_logo.svg";
import "../../assets/css/footer.css";
import "../../assets/css/utils.css";

const Footer: React.FC = () => {
  return (
    <div className="main-container">
      <div className="m-0 top-container">
        <div className="text-center pt-5">
          <p className="f-title">GET OUR LATEST UPDATES</p>
          <div className="klaviyo-form-Y2NKa4"></div>
          <p className="f-title text-left">SOCIAL MEDIA</p>
          <div className="row m-0 text-left social-links">
            <a
              aria-label="instragram"
              href="https://www.instagram.com/e1337metawear?igshid=o0y44pbccr5l"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a
              aria-label="twitter"
              href="https://twitter.com/e1337metawear"
              className="social-icon ml-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>
            <a
              aria-label="linkedin"
              href="https://www.linkedin.com/company/e1337"
              className="social-icon ml-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
            </a>
            <a
              aria-label="discord"
              href="https://discord.gg/e1337"
              className="social-icon ml-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} size="lg" />
            </a>
            <a
              aria-label="youtube"
              href="https://www.youtube.com/channel/UCID3iW9Mb67QhSmyli7hRvg"
              className="social-icon ml-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} size="lg" />
            </a>
            <a
              aria-label="medium"
              href="https://e1337.medium.com"
              className="social-icon ml-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faMedium} size="lg" />
            </a>
          </div>
        </div>
        <div className="text-left pt-5">
          <p className="f-title">SUPPORT</p>
          <ul className="list-items">
            <li>
              <a className="footer-link" href="mailto:info@e1337.pro">
                Contact
              </a>
            </li>
            <li>
              <Link to={FAQ} className="footer-link">
                FAQ
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-left pt-5">
          <p className="f-title">COMPANY</p>
          <ul className="list-items">
            {/* <li>
              <Link
                to={PHYSICAL}
                className="footer-link"
                aria-label="shop physical"
              >
                Shop Physical
              </Link>
            </li> */}
            <li>
              <Link
                to={DIGITAL}
                className="footer-link"
                aria-label="shop digital"
              >
                Shop Digital
              </Link>
            </li>
            <li>
              <Link to={ABOUT} className="footer-link" aria-label="about us">
                About Us
              </Link>
            </li>
            <li>
              <Link to={MEDIA} className="footer-link" aria-label="media">
                Media
              </Link>
            </li>
            <li>
              <Link to={BLOG} className="footer-link" aria-label="blog">
                Blog
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-2 text-left pt-5">
          <p className="f-title">REACH OUT</p>
          <ul className="list-items">
            <li>E1337 Limited</li>
            <li>
              South Tower, World Finance Centre, Harbour City 17 Canton Road,
              13A/F Rooms 05-15
              <span className="d-block">Tsim Sha Tsui, Kowloon</span>
              <span className="d-block">Hong Kong</span>
            </li>
            <li>Tel: +852-53733798</li>
            <li>E-mail: info@e1337.pro</li>
          </ul>
        </div>
        <div className="col-2 text-left pt-5">
          <p className="f-title">CHECKOUT</p>
          <ul className="list-items">
            <li>
              <img src={xpaylogo} alt="xpay" />
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-container text-center pt-5 pb-4">
        <div className="col-12">
          <Link
            to={PRIVACY}
            className="padding-8px-sides privacy-terms"
            aria-label="privacy policy"
          >
            Privacy Policy
          </Link>
          |
          <Link
            to={TERMS}
            className="padding-8px-sides privacy-terms"
            aria-label="terms and conditions"
          >
            Terms of Use
          </Link>
          |{" "}
          <span className="padding-8px-sides">
            Powered by{" "}
            <a
              aria-label="nftstudios webpage"
              className="privacy-terms"
              href="https://www.nftstudios.io/"
              target="_blank"
              rel="noreferrer noopener"
            >
              NFTStudios
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
export default Footer;
