import React from "react";
import { Link, useLocation } from "react-router-dom";
import home from "../../assets/images/menu/home-gray.svg";
import homeV from "../../assets/images/menu/home-violet.svg";
import shop from "../../assets/images/menu/shop-gray.svg";
import shopV from "../../assets/images/menu/shop-violet.svg";
import cart from "../../assets/images/menu/cart-gray.svg";
import cartV from "../../assets/images/menu/cart-violet.svg";
import more from "../../assets/images/menu/more-gray.svg";
import moreV from "../../assets/images/menu/more-violet.svg";
import { HOME, SHOP, MENU, CART, PHYSICAL, DIGITAL } from "../../routes/routes";
import { H_VIOLET, H_GREY } from "../../constants/colours";
import "../../assets/css/menumobile.css";

const MobileMenu: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <div className="menu-mobile">
      <Link to={HOME} aria-label="home">
        <div>
          <img src={pathname === HOME ? homeV : home} alt="icon" />
          <span style={{ color: pathname === HOME ? H_VIOLET : H_GREY }}>
            Home
          </span>
        </div>
      </Link>

      <Link to={SHOP} aria-label="shop">
        <div>
          <img
            src={
              pathname === SHOP ||
              pathname.includes(PHYSICAL) ||
              pathname.includes(DIGITAL)
                ? shopV
                : shop
            }
            alt="icon"
          />
          <span
            style={
              pathname === SHOP ||
              pathname.includes(PHYSICAL) ||
              pathname.includes(DIGITAL)
                ? { color: H_VIOLET }
                : { color: H_GREY }
            }
          >
            Shop
          </span>
        </div>
      </Link>
      <Link to={CART} aria-label="cart">
        <div>
          <img src={pathname === CART ? cartV : cart} alt="icon" />
          <span style={{ color: pathname === CART ? H_VIOLET : H_GREY }}>
            My Cart
          </span>
        </div>
      </Link>
      <Link to={MENU} aria-label="menu mobile">
        <div>
          <img src={pathname === MENU ? moreV : more} alt="icon" />
          <span style={{ color: pathname === MENU ? H_VIOLET : H_GREY }}>
            More
          </span>
        </div>
      </Link>
    </div>
  );
};

export default MobileMenu;
