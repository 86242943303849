import React, { useState, useEffect, createContext } from "react";
import toast from "react-hot-toast";
import { ICartItemProps } from "../components/CartItem";

export interface ICartContextProps {
  show: boolean;
  items: ICartItemProps[];
  subtotal: number;
  loadItems: (item: ICartItemProps[]) => void;
  addItem: (item: ICartItemProps) => void;
  removeItem: (id: number) => void;
  updateItem: (id: number, action: "ADD" | "SUBSTRACT") => void;
  showCart: () => void;
}

export const CartContext = createContext<ICartContextProps | null>(null);

const style = {
  borderRadius: "10px",
  background: "#333",
  color: "#fff",
  marginTop: "100px",
  border: "1px solid #BF56E5",
};

const CartProvider: React.FC = ({ children }) => {
  const [areItemsLoaded, setAreItemsLoaded] = useState<boolean>(false);
  const [items, setItems] = useState<ICartItemProps[]>([]);
  const [subtotal, setSubTotal] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    refreshLocalStorage(items);
    setSubTotal(
      items.reduce(
        (acc: number, curr: ICartItemProps) => acc + curr.price * curr.quantity,
        0
      )
    );
  }, [items]);

  const addItem = (item: ICartItemProps) => {
    toast.success("Product added to cart!", { style: style });
    let existItem = items.find((i) => i.variantId === item.variantId);
    if (existItem) {
      setItems(
        items.map((i) =>
          i.id === item.id
            ? { ...i, quantity: (i.quantity += item.quantity) }
            : i
        )
      );
      return;
    }
    setItems([...items, item]);
  };

  const removeItem = (id: number) => {
    setItems(items.filter((f) => f.variantId !== id));
  };

  const updateItem = (id: number, action: string) => {
    switch (action) {
      case "ADD":
        setItems(
          items.map((i) =>
            i.variantId === id ? { ...i, quantity: i.quantity + 1 } : i
          )
        );
        break;
      case "SUBSTRACT":
        setItems(
          items.map((i) =>
            i.variantId === id && i.quantity > 1
              ? { ...i, quantity: i.quantity - 1 }
              : i
          )
        );
        break;
      default:
        break;
    }
  };

  const refreshLocalStorage = (items: ICartItemProps[]) => {
    localStorage.setItem("cart", JSON.stringify(items));
  };

  const showCart = () => {
    setShow(!show);
  };

  const loadItems = (items: ICartItemProps[]) => {
    if (areItemsLoaded) {
      return;
    }

    setItems(items);
    setAreItemsLoaded(true);
  };

  return (
    <CartContext.Provider
      value={{
        show,
        items,
        subtotal,
        loadItems,
        showCart,
        addItem,
        removeItem,
        updateItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
export default CartProvider;
