import "./Loading.css";
import loaderImage from "./../../assets/images/loader/loader.png";

const Loading = () => (
  <div
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {/* <Loader type="Puff" color="#BF56E5" height={100} width={100} /> */}
    <img src={loaderImage} alt="Loading" className="loaderImg" />
  </div>
);

export default Loading;
