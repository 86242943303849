import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { ProductWishlistType } from "../types/ProductTypes";
import { Web3Context } from "../context/Web3Context";
import { WISHLIST } from "../constants/apiEndpoints";
import toast from "react-hot-toast";
import axios from "../config/axios";

export interface IWishlistContextProps {
  wishlistProducts: ProductWishlistType[];
  addProductToWishlist: (id: number) => void;
  checkIfIsWishlisted: (id: number) => boolean;
  removeFromWishlist: (id: number) => void;
  getWishlistProducts: () => void;
}

export const WishlistContext = createContext<IWishlistContextProps | null>(
  null
);

const style = {
  borderRadius: "10px",
  background: "#333",
  color: "#fff",
  marginTop: "100px",
  border: "1px solid #BF56E5",
};

const WishlistProvider: React.FC = ({ children }) => {
  const { walletAddress, signature } = useContext(Web3Context);
  const [wishlistProducts, setWishlistProducts] = useState<
    ProductWishlistType[]
  >([]);

  const getWishlistProducts = useCallback(() => {
    axios
      .get(WISHLIST, {
        headers: {
          "auth-signed-message": signature,
          "auth-public-address": walletAddress,
        },
      })
      .then(({ data }) => {
        setWishlistProducts(data);
      })
      .catch((err) => console.log(err));
  }, [signature, walletAddress]);

  useEffect(() => {
    if (signature === "" || walletAddress === "") {
      return;
    }
    getWishlistProducts();
  }, [getWishlistProducts, signature, walletAddress]);

  const addProductToWishlist = (id: number) => {
    if (!checkWalletConnected()) {
      return;
    }

    toast.promise(
      axios.post(
        WISHLIST,
        { productId: id },
        {
          headers: {
            "auth-signed-message": signature,
            "auth-public-address": walletAddress,
          },
        }
      ),
      {
        loading: "Adding...",
        success: () => {
          getWishlistProducts();
          return "Product Added to Wishlist!";
        },
        error: (err) =>
          err.response ? err.response.data.message : "Oops! Please try again!",
      },
      { style: style }
    );
  };

  const checkIfIsWishlisted = (id: number) => {
    return wishlistProducts.some((item) => item.productId === id);
  };

  const removeFromWishlist = (id: number) => {
    if (!checkWalletConnected()) {
      return;
    }

    toast.promise(
      axios.delete(`${WISHLIST}/${id}`, {
        headers: {
          "auth-signed-message": signature,
          "auth-public-address": walletAddress,
        },
      }),
      {
        loading: "Removing product...",
        success: () => {
          getWishlistProducts();
          return "Removed from Wishlist!";
        },
        error: (err) => "Oops! Please try again!",
      },
      { style: style }
    );
  };

  const checkWalletConnected = () => {
    if (signature === "" || walletAddress === "") {
      toast.error("Please connect your wallet first", { style: style });
      return false;
    }
    return true;
  };

  return (
    <WishlistContext.Provider
      value={{
        wishlistProducts,
        getWishlistProducts,
        addProductToWishlist,
        checkIfIsWishlisted,
        removeFromWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};
export default WishlistProvider;
