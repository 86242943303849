export const BLUE = "blue";
export const VIOLET = "violet";
export const YELLOW = "yellow";

// HEXAS
export const H_BLACK = "#000000";
export const H_BLUE = "#7DC9D1";
export const H_VIOLET = "#BF56E5";
export const H_GREY = "#7f7f7f";
export const H_DISABLED_GREY = "#676767";
